import React from "react";
import { SEO, PageLayout, HeroElement } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class StoryPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Our Story"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                fishermanBusiness.ctas.address,
                fishermanBusiness.ctas.phone,
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <h1>Our Story</h1>
              <p>
                13 th Street Bar-B-Q, Home of the Original Pork Chop Sandwich,
                was the culmination of the lifelong vision of owner and
                operator, Jimmy Johnson. For 25 years, Jimmy worked as an
                insurance agent. During those years he spent a great deal of
                time doing what he loved the most, grilling barbeque for his
                family and friends while his wife Gala made the barbeque sauce.
                The sauce recipe was originally passed down from Jimmy’s
                grandmother. Jimmy and Gala made a few adjustments to create the
                mouth-watering sauce that is still used today.
              </p>
              <p>
                Jimmy had always dreamed of having his own restaurant but never
                thought it would be possible. Harris Copeland, a very close
                friend, approached him one day and offered to be is partner. So,
                with little money and a lot of passion, 13 th Street Bar-B-Q
                opened its doors on August 26, 1988. The location on 13 th
                Street in Phenix City was small, barely able to hold 20 people,
                but it was big on business. It was this small location which not
                only formed the foundation of the business itself, but
                friendships that will last forever.
              </p>
              <p>
                Slay Johnson, Jimmy’s son, ran the restaurant for his father
                until he could officially retire from the insurance business.
              </p>
              <p>
                The business grew faster than expected and in 1991, 13 th Street
                Bar-B-Q moved to a larger building on 7 th Ave. just behind the
                original location, where it is still located today. Slay bought
                out Harris Copeland in 1993 and father and son became business
                partners.
              </p>
              <p>
                Jimmy Johnson, known to most as "Mr. Jimmy", loved to cook and
                loved to make his customers laugh and smile. He wanted everyone
                to be happy and enjoy their meal as well as the atmosphere at
                his restaurant. He genuinely cared about his customers, often
                asking about how their families were doing or how work was
                going. 13 th Street Bar-B-Q has a very pleasant home-like
                atmosphere. A dedicated group of “regulars”, some of whom visit
                daily, know that just by walking through the door, the friendly
                staff is already preparing their order.
              </p>
              <p>
                As the business continued to grow, there were a lot of requests
                for another location. On April 4, 2000, 13 th Street Bar-B-Q of
                Columbus opened on Veterans Parkway. It was at that time that
                Slay decided to start franchising the business. The first
                franchise opened in 2001 and currently there are 4 13 th Street
                Bar-B-Q locations, Phenix City, Columbus, Ladonia and Cataula.
              </p>
              <p>
                13 th Street Bar-B-Q was picked as one of the Top 10 Places to
                Eat in Alabama in 2005 and in 2007. Alabama Public Television
                chose 13 th Street as one of their Top 10 Barbeque Restaurants
                in Alabama in 2004. A film crew spent two days filming and this
                special has been aired several times over the years. The Pork
                Chop Sandwich has been listed as one of the "100 Dishes to Eat
                in Alabama Before You Die", a brochure published by the Alabama
                Dept. of Tourism.
              </p>
              <p>
                Jimmy and Slay Johnson worked side-by-side for 18 years, until
                January 8, 2006 when Jimmy passed away. Slay, now sole owner,
                has kept the business running and remodeled the current location
                in 2018.
              </p>
              <p>
                The staff and patrons hope you will come by to visit and try one
                of the famous Pork Chop Sandwiches.
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        address {
          text
          link
          internal
        }
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Our Story" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
